import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import MaterialTable from "material-table";


import Sidebar from '../../../Template/Sidebar/Sidebar';
import Header from '../../../Template/Header/Header';
import { baseUrl } from '../../../../config';

import DatePicker from "react-datepicker";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@mui/material/Typography';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { clickColor, inArray } from './../../../../library/util';
import objAdvancedFilter from './../../../../library/advancedFilterLibFn';

import './../css/profit.css'
import './../css/tiered.css'
import './../css/Complete.css'

import { formatNumberWithToFixed, roundToFour, roundToTwo, inArrayForString } from '../common/commanFn';


import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import { flexbox } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';

// import ReportTypeSelectBox from '../../../Common/ReportTypeSelect';

const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: 'white'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'lightblue'
      : isSelected
        ? '#2a7bc0'
        : undefined,
    zIndex: 1
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  })
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AddIconClasses = { color: '#337ab7', fontSize: '30px', marginLeft: '220px', marginTop: "-15px", cursor: 'pointer' };
const RestoreIconClasses = { color: '#337ab7', fontSize: '20px', marginLeft: '5px', marginBottom: "-5px", cursor: 'pointer' };
const style_advanced = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Complete(text) {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedrowList, setSelectedRowList] = useState([]);


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  const [ReportTitle, setReportTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23, 59, 59, 0));

  const [group, setgroup] = useState('');
  const [parentGroup, setParentGroup] = useState('Group Category');
  const [parentGroupFilterValue, setParentGroupFilterValue] = useState('')
  const [parentGroupArray, setParentGroupArray] = useState([])
  const [childGroup, setChildGroup] = useState('');
  const [childGroupFilterValue, setChildGroupFilterValue] = useState('')
  const [childGroupArray, setChildGroupArray] = useState([])
  const [grandChildGroup, setGrandChildGroup] = useState('');
  const [grandChildFilterValue, setGrandChildFilterValue] = useState('');
  const [grandChildGroupArray, setGrandChildGroupArray] = useState([])
  const [groupArray, setgroupArray] = useState([]);

  const [REFCB, setREFCB] = useState(true);
  const [Refund, setRefund] = useState('9');
  const [CB, setCB] = useState('1');
  const [resetPage, setResetPage] = useState(0);





  let midTitle = '';
  const location = useLocation()
  let reportTypeCategory = [
    {
      "value": "combined",
      "label": "Combined"
    },
    {
      "value": "shopware",
      "label": "ShopWare"
    },
    {
      "value": "wowsuite",
      "label": "WowSuite"
    }

  ]
  const loadFilterLabelSource = (inputValue) => {

    let tempCategory = reportTypeCategory;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  const [type, setType] = useState('shopware')
  const handleChangeP = (target) => {
    console.log('target', target.value)
    if (target != undefined) {
      setType(target.value);
    }
  }
  const reportOrderType = location.pathname.split("/").pop();

  let reportTitle = `Profits Report (ShopWare)`;

  if (type == 'wowsuite') {
    reportTitle = `Profits Report (Wowsuite)`;
  } else if (type == 'combined') {
    reportTitle = `Combined Profit Report`;
  }

  const selectInputRef = useRef();
  const REFCBCheckBox = useRef();
  const selectChildGroupRef = useRef();
  const selectGrandChildGroupRef = useRef();


  /*********** Advanced Filter **********/

  const handleSignOut = () => {
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  checkUserTypeFn(history);

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;

    if (usertype != 'owneradmin' && usertype != 'superadmin') {
      history.push('/');
    }
  }

  const [reportData, setReportData] = useState([])
  const [loader, setLoader] = useState(false)
  axios.defaults.timeout = 36000000;//10min
  const [message, setMessage] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  const [successMsg, setSuccessMsg] = useState('')

  useEffect(() => {
  }, [resetPage])


  const ResetFilter = () => {
    if (REFCB == true)
      REFCBCheckBox.current.click();
    setMessage('')
    setEndDate(new Date(Date.now()))
    setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setREFCB(false);
    setRefund('');
    setCB('');
    setgroupArray([])
    setReportData([]);
    window.location.reload('/')
  }



  const fetchApi = async () => {
    let filterStringP = '';
    let filterStringT = '';
    let filterStringG = '';
    let filterStringC = '';

    if (startDate == null) {
      setMessage('Start Date must not be blank')
      return false;
    } if (endDate == null) {
      setMessage('End Date must not be blank')
      return false;
    } else {
      setMessage('')
    }

    let API_URL = '';
    setLoader(true);
    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profitview/` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB + "&parentGroup="
    API_URL = API_URL + `&reportOrderType=` + type
    try {
      const response = await axios.get(API_URL, {});
      if (response.data.result.length > 0) {
        response.data.result.forEach((i, j) => {
          i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString();
          i.AOV = "$" + formatNumberWithToFixed(i.AOV).toLocaleString();
          i.cpa_cost = "$" + formatNumberWithToFixed(i.cpa_cost).toLocaleString();
          i.cpa_cost_per_order = "$" + formatNumberWithToFixed(i.cpa_cost_per_order).toLocaleString();
          i.cogs = "$" + formatNumberWithToFixed(i.cogs).toLocaleString();
          i.cogs_per_order = "$" + formatNumberWithToFixed(i.cogs_per_order).toLocaleString();
          i.refund_dollars_amount = "$" + formatNumberWithToFixed(i.refund_dollars_amount).toLocaleString();
          i.chargebacks_dollars_amount = "$" + formatNumberWithToFixed(i.chargebacks_dollars_amount).toLocaleString();
          i.processing_cost = "$" + formatNumberWithToFixed(i.processing_cost).toLocaleString();
          i.processing_cost_per_order = "$" + formatNumberWithToFixed(i.processing_cost_per_order).toLocaleString();
          i.transaction_fee_cost = "$" + formatNumberWithToFixed(i.transaction_fee_cost).toLocaleString();
          i.transaction_fee_cost_per_order = "$" + formatNumberWithToFixed(i.transaction_fee_cost_per_order).toLocaleString();
          i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
          i.shipping_cost = "$" + formatNumberWithToFixed(i.shipping_cost).toLocaleString();
          i.shipping_cost_per_order = "$" + formatNumberWithToFixed(i.shipping_cost_per_order).toLocaleString();
          i.handling_cost = "$" + formatNumberWithToFixed(i.handling_cost).toLocaleString();
          i.handling_cost_per_order = "$" + formatNumberWithToFixed(i.handling_cost_per_order).toLocaleString();
          i.customer_service_cost = "$" + formatNumberWithToFixed(i.customer_service_cost).toLocaleString();
          i.customer_service_cost_per_order = "$" + formatNumberWithToFixed(i.customer_service_cost_per_order).toLocaleString();
          i.net_profit_per_order = "$" + formatNumberWithToFixed(i.net_profit_per_order).toLocaleString();
          i.profit_margin = roundToTwo(i.profit_margin * 100) + "%";
          i.chargebacks_dollars_percent = roundToTwo(i.chargebacks_dollars_percent * 100) + "%";
          i.chargebacks_rate_percent = roundToTwo(i.chargebacks_rate_percent * 100) + "%";
          i.refund_dollars_percent = roundToTwo(i.refund_dollars_percent * 100) + "%";
          i.refund_rate_percent = roundToTwo(i.refund_rate_percent * 100) + "%";
        });
        setReportData(response.data.result);
        setLoader(false)
      }else{
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
      setMessage('Something went wrong');
    }
  }

  const fetchApiDownload = () => {
    let API_URL = '';
    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profitview/download` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&reportOrderType=" + type + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB
  }
  const PrettoSlider = styled(Slider)({
    color: '#337ab7',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 10,
      background: 'unset',
      padding: 0,
      width: 25,
      height: 25,
      zIndex: -10,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#337ab7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });


  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>
      <section id="content-wrapper">
        <div className='FilterDivSection'>
          <label>Report Type</label>
          <label className='startDateStyle_combined'>Start Date</label>
          <label className='endDateStyle_combined'>End Date</label>

          {!REFCB ? '' :
            <label className='RefundStyle'>Refund Percent</label>
          }
          {!REFCB ? '' :
            <label className='chargebackStyle_combined'>Chargeback Percent</label>
          }

          <div className="input-bar">
            <div className="input-bar-item width60">
              <div style={{ width: '155px', height: '10px' }}>
                <AsyncSelect
                  ref={selectInputRef}
                  hideSelectedOptions={false}
                  components={animatedComponents}
                  onChange={e => handleChangeP(e)}
                  cacheOptions
                  name="type"
                  placeholder="Report Type"
                  defaultOptions
                  defaultValue={reportTypeCategory[1]}
                  maxMenuHeight={200}
                  className="widthSelectInputCom"
                  classNamePrefix="select"
                  styles={colourStyles}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadFilterLabelSource}
                  defaultInputValue={type}
                />
              </div>
            </div>

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={startDate}
                className='width122x'
                onChange={(date) => setStartDate(date)}
                placeholder='Start Date'
              />

            </div>

            &nbsp;&nbsp;&nbsp;

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={endDate}
                className='width122x'
                onChange={(date) => setEndDate(date)}
                placeholder='End Date'
              />

            </div>
            &nbsp;&nbsp;&nbsp;

            <div className="checkboxDiv">
              <FormGroup>
                <FormControlLabel control={<Checkbox
                  ref={REFCBCheckBox}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  name='Estimate Refunds/CBs'
                  value={REFCB}
                  defaultChecked={REFCB}
                  onChange={() => setREFCB(!REFCB)}
                />}
                  label="Estimate Refunds/CBs" />
              </FormGroup>
            </div>

            {!REFCB ? '' :

              <div className="input-bar-item">


                <Box width={150}></Box>

                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={Refund}
                  onChange={e => setRefund(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            {!REFCB ? '' :

              <div className="input-bar-item">



                {/* <Box sx={{ m: 3 }} /> */}
                <Box width={150}></Box>
                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={CB}
                  onChange={e => setCB(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            <div className="input-bar-item">
              <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

            </div>
            <div className='input-bar-item'>
              <button type="button" onClick={ResetFilter} className="btn btn-primary">Reset</button>
            </div>

            <div className='downloadReportBtn'>
              {

                <button type="button" onClick={fetchApiDownload} className="btn btn-primary">Download</button>

              }
            </div>
          </div>

        </div>
        {loader ?
          <div className='loaderDiv'>
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> :
          <MaterialTable
            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={[
              {
                title: "Group", field: "group",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },

              {
                title: "Total.Orders", field: "total_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Total.Revenue", field: "total_revenue",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "AOV", field: "AOV",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Net.Profit", field: "net_profit",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Net.Profit.per.Order", field: "net_profit_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Profit.Margin", field: "profit_margin",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "CPA.Cost", field: "cpa_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "CPA.Cost.per.Order", field: "cpa_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "COGS", field: "cogs",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "COGS.per.Order", field: "cogs_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Shipping.Cost", field: "shipping_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Shipping.Cost.per.Order", field: "shipping_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number.of.Refunds", field: "number_of_refunds",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Refund.Rate.Percent", field: "refund_rate_percent",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Refund.Dollars.Amount", field: "refund_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Refund.Dollars.Percents", field: "refund_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number.of.Chargebacks", field: "number_of_chargebacks",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Chargebacks.Rate.Percent", field: "chargebacks_rate_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Chargebacks.Dollars.Amount", field: "chargebacks_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Chargebacks.Dollars.Percent", field: "chargebacks_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing.Cost", field: "processing_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing.Cost.per.Order", field: "processing_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Transaction.Fee.Cost", field: "transaction_fee_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Transaction.Fee.Cost.per.Order", field: "transaction_fee_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Handling.Cost", field: "handling_cost",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Handling.Cost.per.Order", field: "handling_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Customer.Service.Cost", field: "customer_service_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },



              {
                title: "Customer.Service.Cost.per.Order", field: "customer_service_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
            ]}
            data={
              reportData
            }
            title={reportTitle}
            options={{
              actionsColumnIndex: -1,
              addRowPosition: "first",
              pageSize: 10,
              loadingType: 'overlay',
              paging: false,
              exportButton: true,
              maxBodyHeight: '500px',

              headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
              })
            }}
          />
        }
      </section>

    </div>

  )
}

export default Complete