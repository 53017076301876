import React, { useState, useEffect } from 'react';
import axiosIntance, { fetchInstance, callAPI } from '../../helper/axios';
import { useHistory } from "react-router-dom";
import './woworder.css'
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from '../../config';
import { clickColor, inArray } from './../../library/util';
import Loader from "react-loader-spinner";
import FilePlaceholder from './../../assets/cloud-computing.png'
import downloadFilePlaceholder from './../../assets/download.png'
import FileIcon from './../../assets/google-docs.png'
import Message from './Message';
import AutomationSyncWow from '../Common/AutomationSyncWow';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import TableTitleInfo from '../Common/TableTitleInfo';
import { tableInfoMessages } from '../Common/TableInfoMessage';
function Woworder() {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

const handleTooltipOpen = () => {
    if (open == true) {
        setOpen(false);
    } else if (open == false) {
        setOpen(true);
    }
};


  const api_module = 'woworder';
  const tableTitleInfoMes=tableInfoMessages[api_module]
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false)
  checkUserTypeFn(history);
  const [selectedrowList, setSelectedRowList] = useState([]);
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadBtn, setUploadBtn] = useState(true)
  let tableId = 'woworder';
  const downloadFn = async e => {
    let tableName = tableId;
    setLoader(true)
    window.location.href = baseUrl + `/export/${tableName}`;
    setLoader(false)
  }
  const onChange = e => {
    console.log('on changes fn*********');
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setUploadBtn(true)
    setSuccessMsg('');
    setMessage('');

  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploadBtn(false)

    try {
      const res = await axiosIntance.post(baseUrl + `/${api_module}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setLoader(true)
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 80) / progressEvent.total)));
          //setTimeout(() => setUploadPercentage(0), 10000);
        }
      });

      const { fileName, filePath } = res.data;
      if (res.data.success === true) {
        setLoader(false)
        setSuccessMsg(res.data.message);
        setUploadPercentage(100)
        setFilename('')
      } else {
        setLoader(false)
        setMessage(res.data.message);
      }
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      setLoader(false)
      console.log(err)
      setMessage('Something went wrong');
    }
  }


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;
    console.log('in check usertype fn');
    console.log(usertype)
    if (usertype != 'admin' && usertype != 'superadmin' && usertype != 'subadmin' && usertype != 'databasehelper' && usertype != 'owneradmin') {
      history.push('/');
    }

  }

  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }
  const [resultCount, setResultCount] = useState(0);
  const [resultCountCounter, setResultCountCounter] = useState(0);

  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  let columnsData = [
    { title: "Order Number", field: "order_number" },
    { title: "Order ID", field: "order_id" },
    { title: "Order Behavior Name", field: "order_behavior_name" },
    { title: "TransactionId", field: "transaction_id" },
    { title: "Order Type", field: "order_type" },
    { title: "Order Status Name", field: "order_status_name" },
    { title: "Name (PaymentTypes)", field: "name_payment_types" },
    { title: "Receipt Status Name", field: "receipt_status_name" },
    { title: "Mid Code", field: "mid_code" },
    { title: "Payment Processor Name", field: "payment_processor_name" },
    { title: "Product Name", field: "product_name" },
    { title: "SKUId", field: "sku_id" },
    { title: "ReferringUrl", field: "referring_url" },
    { title: "LandingUrl", field: "landing_url" },
    { title: "CountryCode", field: "country_code" },
    { title: "Country Name", field: "country_name" },
    { title: "Affiliate ID", field: "affiliate_id" },
    { title: "S1", field: "s1" },
    { title: "S2", field: "s2" },
    { title: "S3", field: "s3" },
    { title: "S4", field: "s4" },
    { title: "S5", field: "s5" },
    { title: "Order Create Date", field: "order_create_date" },
    { title: "Create Date (Receipts)", field: "create_date_receipts" },
    { title: "Campaign Id", field: "campaign_id" },
    { title: "Brand", field: "campaign_offer" },

    { title: "Parent Campaign", field: "parent_campaign" },
    { title: "Campaign Name", field: "campaign_name" },
    { title: "TrackingNumber", field: "tracking_number" },
    { title: "ShippingReference", field: "shipping_reference" },

    { title: "Email", field: "email" },
    { title: 'FirstName', field: 'first_name' },
    { title: "LastName", field: "last_name" },
    { title: "Phone Number", field: "phone_number" },
    { title: "Address 1", field: "address1" },

    { title: "Address 2", field: "address2" },
    { title: 'City', field: 'city' },
    { title: "State", field: "state" },
    { title: "ZipCode", field: "zipcode" },
    { title: "Currency Code", field: "currency_code" },

    { title: "Order Price", field: "order_price" },
    { title: 'Sales Tax', field: 'salex_tax' },
    { title: "Amount", field: "amount" },
    { title: "Order Price USD", field: "order_price_usd" },
    { title: "Sales Tax USD", field: "salex_tax_usd" },

    { title: "Amount USD", field: "amount_usd" },
    { title: 'Order Quantity (Units Sold)', field: 'order_quantity' },
    { title: "Is Scheduler", field: "is_scheduler" },
    { title: "Scheduler Date", field: "scheduler_date" },
    { title: "Created By", field: "created_by" },
    { title: "Updated By", field: "updated_by" },
  ];
  const title = 'Orders - Wowsuite';
  const tableOption = {
    sorting: false,
    search: false,
    loadingType: 'overlay',
    maxBodyHeight: '500px',
    headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
    })
  }



  useEffect(() => {
    setLoader(true)
    loadData();
    return () => {
      console.log('un subscribe')
    }



  }, [])

  let loadData = async () => {
    try {
      const response = await callAPI('GET', `${api_module}/count`, {})
      setResultCount(response.data.result);
      setResultCountCounter(1);
      setLoader(false)
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async () => {
    try {
      const response = await callAPI('DELETE', api_module, {})
      setSuccessMsg('Deleted!')
    } catch (e) {
      setMessage('Something went wrong');
    }
  }

  const SubmitDelete = (e) => {

    confirmAlert({
      title: '',
      message: 'Are you sure want to delete All data',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDataFn()
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }
  return (
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />


      <div class="topbar">
        <div className='successMsg viewScreen'>{successMsg}</div>
        <div className='errorMsg viewScreen'>{message}</div>
        <div className="overlay-content popup1 fileuploadcontainer">
          <div className='fileupload_div'>

            {message ? <Message msg={message} /> : null}
            <form onSubmit={onSubmit}>
              <div className="custom-file mb-4">

                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={onChange}
                  accept=".xls,.xlsx"
                />
                <label className='custom-file-label' htmlFor='customFile'>
                  {filename ?
                    <>
                      <div className="placeholder margin-bottom">
                        <img src={FileIcon} alt="file-placeholder" />
                      </div>
                      <label className="d-block">{filename}</label>
                    </>
                    :
                    <>
                      <div className="placeholder">
                        <img src={FilePlaceholder} alt="file-placeholder" />
                      </div>
                      <label className="d-block">upload</label>
                    </>
                  }
                </label>
              </div>
              {
                filename ?
                  <>
                    {/* <Progress percentage={uploadPercentage} /> */}
                    {loader &&
                      <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="50" width="50" /></div>
                    }
                    {uploadBtn &&
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Upload"
                          className="btn btn-primary margin-bottom mt-8"
                        />
                      </div>
                    }
                  </>
                  :
                  ""
              }

            </form>
            {uploadedFile ? <div className="row mt-5">
              <div className="col-md-6 m-auto"></div>
              <h3 classNAme="text-center">{uploadedFile.fileName}</h3>
              <img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
            </div> : null}

          </div>
          {resultCount > 0 ?
            <div className='fileupload_div_right'>

              {message ? <Message msg={message} /> : null}
              <form onSubmit={onSubmit}>
                <div className="custom-file mb-4">

                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    onChange={onChange}
                    accept=".xls,.xlsx"
                  />
                  <label className='custom-file-label' htmlFor='customFile'>
                    <div className="placeholder">
                      <img src={downloadFilePlaceholder} alt="file-placeholder" onClick={downloadFn} />
                    </div>
                    <label className="d-block">Export</label>
                  </label>
                </div>


              </form>
              {uploadedFile ? <div className="row mt-5">
                <div className="col-md-6 m-auto"></div>
                <h3 classNAme="text-center">{uploadedFile.fileName}</h3>
                <img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
              </div> : null}

            </div>
            : ''}

        </div>
        <div className='delete-div'>
          {
            resultCount > 0 ? <button type="button" onClick={SubmitDelete} className="btn btn-primary">Delete All </button> : ''

          }

        </div>
        <div className='uploadDownloadDivMidSec'><AutomationSyncWow name={api_module}  /></div> 
      </div>


      <section id="content-wrapper">

        {loader &&
          <div className='loaderDiv'>
            <Loader
              type="Circles"
              color="#b4d6fa"
              height={100}
              width={100}
            //timeout={1000} //3 secs
            />
          </div>
        }
        {resultCountCounter > 0 &&

          <MaterialTable
            // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={columnsData}
            data={query =>
              new Promise((resolve, reject) => {
                let url = baseUrl + `/${api_module}?`
                url += `&_page=${query.page + 1}`
                url += `&_limit=${query.pageSize}`
                fetchInstance(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.result,
                      page: query.page,
                      totalCount: resultCount,
                    })
                  })
              })
            }

            options={tableOption}
            

            title={
            <>
              <h3>{title}&nbsp;&nbsp;&nbsp;
                <Tooltip 
                PopperProps={{disablePortal: true}} 
                onClose={handleTooltipClose} 
                disableFocusListener 
                disableHoverListener 
                disableTouchListener                    
                open={open} 
                title={<h5 className="tooltipClass">{tableTitleInfoMes}
                </h5>}>
              <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
              </Tooltip></h3>
              </>
              }
        
            // title={TableTitleInfo}
          />
        }
      </section>
    </div>
  )
}

export default Woworder
